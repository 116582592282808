.wr-targets {}

.primary-goal-title {
    @include secondaryMedium;
    font-size: 29px;
    font-weight: 600;
    line-height: 1.3;
    color: #002159;

    span.by {
        @include primaryRegular;
        font-size: 20px;
        color: $dark;
    }

    .primary-goal-month {
        text-transform: capitalize;
    }
}

.goal-card {
    padding: 10px;

    &.primary-goal,
    &.placeholder-card {
        .goal-label {
            font-size: 17px;
            font-weight: 600;
            @include primaryBold;
            color: $primary;
        }

        .goal-title {
            font-size: 1.5rem;
            @include secondaryMedium;
            color: $primary;
        }

        .goal-description {
            font-size: 14px;
            @include primaryRegular;
            color: $dark;
        }
    }

    &.primary-goal {
        position: relative;
        padding: 12px;

        .edit-goal {
            margin-bottom: 1rem;
            text-align: right;
        }

        @media screen and (min-width: 1200px) {
            .edit-goal {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        .card-header {
            display: flex;
            flex-direction: row;
            background: none;
            border-bottom: none;
            padding: 0;

            @media screen and (max-width: 560px) {
                flex-direction: column;
                text-align: center;
            }

            .goal-card-image {
                margin-right: 20px;
                max-height: 100px;

                img {
                    max-height: 100px;
                }
            }

            .goal-title {
                margin-bottom: 0;
            }
        }

        .card-body {
            padding: 0;
            margin-top: 1rem;

            .goal-countdown {
                display: flex;
                align-items: center;

                label {
                    margin: 0;
                    color: #627D98;
                    font-size: 14px;
                }

                h4 {
                    font-family: $font-secondary;
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 0;
                    color: #002159;
                }
            }

            .goal-forecast {
                padding: 0.5rem;
                display: flex;
                background: $nav-bgcolor;
                width: fit-content;
                justify-content: flex-end;
                width: 100%;

                .goal-stat {
                    color: #002159;
                    border: 1px solid #002159;
                    border-radius: 6px;
                    text-align: center;
                    padding: 13px 15px;
                    font-weight: 500;
                    width: 50%;
                }

                .goal-indicator {
                    background: #fff;
                    border: 1px solid #002159;
                    text-align: center;
                    border-radius: 6px;
                    padding: 0.25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    span {
                        display: block;
                    }

                    &.success {
                        border-color: $success;
                        color: $success;

                        .badge {
                            background: $success;
                            color: #fff;
                        }
                    }
                }
            }

            @media screen and (max-width: 560px) {
                .goal-forecast {
                    flex-direction: column;

                    .goal-stat,
                    .goal-indicator {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    &.placeholder-card {
        text-align: center;

        .goal-card-image {
            margin: auto;
            margin-bottom: 1rem;
        }
    }
}

.modal.set-goal-modal {
    .modal-content {
        .modal-body {
            // padding-top: 0;
        }
    }

    .set-goal-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100px;
        }
    }

    .set-goal-title {
        text-align: center;
        @include secondaryMedium;
        margin-bottom: 1rem;
        max-width: 450px !important;
        margin-top: 30px;
        font-size: 18px;
    }

    .set-goal-title,
    .set-goal-description {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .set-goal-date {
        display: inline-block;
        max-width: 200px;
        margin-left: 1rem;
        border-bottom: 2px solid #0081B7;
        color: #0081B7;
    }

    .set-goal-year {
        .react-datepicker-wrapper {
            .react-datepicker__input-container>input {
                width: 100px;
                border: none;
                color: #0081B7;
                text-align: center;
                height: 45px;

                &::placeholder {
                    color: #0081B7 !important;
                }
            }
        }
    }

    .set-goal-month {
        .form-control {
            width: 180px;
            border: none;
            color: #0081B7;
            text-align: center;
            padding: 0;
            height: 45px;
            font-size: 18px;
        }
    }

    .modal-footer {
        //padding-top: 0;
        display: flex;
        justify-content: space-between;

        .set-goal-pagination {
            span {
                display: inline-block;
                position: relative;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid $primary;
                margin: 0 3px;

                &.active {
                    background: $primary;
                }
            }
        }
    }
}