@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-checkbox-tree/src/scss/react-checkbox-tree.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import '../features/reports/style_configurations.scss';

// Custom Variables
$nav-icons: #89909c;

$brand-dark: #3a4250 !important;

$primary-light: #0081b71a;
$primary: #0081b7;
$dark: #414141;
$light: #616161;

$bg-color: #f3f4f5;
$nav-bgcolor: #f8f8f9;

$black: #1f2539 !default;
$white: #ffffff !default;

$grey-lighter: #f3f4f5;
$grey-light: #ebebeb;
$grey-medium: #a6adc6;
$grey-dark: #2e3539;

$brand-grey-light: #a6adc6 !default;
$brand-grey-medium: #70899d !default;

$success: #27ae60;
$danger: #e66b6b;

// Fonts
$font-primary: 'Roboto', sans-serif;
$font-family-sans-serif: 'Lato', 'Helvetica Neue', Arial, sans-serif, -apple-system;
$font-secondary: 'Lexend', sans-serif;

// Buttons
// $input-btn-padding-x: 1.25rem;

@import '~bootstrap/scss/bootstrap';
@import 'mixins';
@import 'features/disclosure/disclosure';
@import 'features/reductionplan/targetsStyles';

/* Mixin */
$medium-width: 1024px;
$large-width: 1280px;

@mixin md-screen { @media (max-width: $medium-width) {     
  @content;
 }
}

@mixin lg-screen { @media (max-width: $large-width) {       
   @content;
 }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-sans-serif;
  font-size: 13px;
  width: 100%;
  overflow-x: hidden;
  -webkit-print-color-adjust: exact; /*chrome & webkit browsers*/
  color-adjust: exact; /*firefox & IE */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cve-auth-provider {
  .main-header, 
  .sidebar,
  .report-header-container,
  .back-button {
    display: none !important;
  }
  .table-bordered {
    border: none !important;
  }
  .chart-container {
    .chart-graph {
      max-width: 80%;
    }
  }
  .responsive-table {
    height: auto !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
  .report-header {
    display: block !important;
  }
  .offset-main {
    width: 100% !important;
  }
  .print-wrapper {
    @media print {
      margin-top: 0;
      margin-left: 0;
    }
  }
  .report-text {
    @media print {
      margin-left: 0;
    }
  }
  .chart_print_wrapper {
    @media print {
      margin: 0;
    }
  }
}

.table-container {
  overflow-y: hidden;
  overflow-x: auto;
}

.report-container h4.pr-3{
  flex: 1;
}

.hidden {
  display: none;
}

.offset-main {
  width: calc(100% - 100px);

  .offset-second {
    width: calc(100% - 260px);
    padding-top: 10px;
    padding-bottom: 30px;
    @include lg-screen {     
      width: 100%;
    }
    &.offset-expanded {
      transition: width 500ms;
      width: calc(100% - 3px);
    }
  }
}

.nav-icons {
  margin-right: 5px;
  color: $nav-icons;
}

.resend-email-container {
  border: none;
  font-size: 13px;
  .btn {
    padding: 0;
    border: none !important;
    color: #01b19f;
    background: none !important;
    &:hover:enabled {
      color: #0081b7 !important;
      background: none !important;
    }
  }
}

.header-sub-title {
  font-size: 14px !important;
  font-weight: bold;
  color: $brand-dark;
  cursor: pointer;

  svg {
    width: 18px;
  }
}

.header-nav {
  padding: 17px 22px;
  border-bottom: 2px solid #f5f6f6;

  .logo {
    width: 166px;
    padding: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.value {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
}
.loader {
  left: 50%;
  top: 40%;
  z-index: 1000;
  position: absolute;
}

//Primary Navbar Styles
.sidebar--primary {
  min-height: calc(100vh - 76px);
  padding: 15px 10px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: $nav-bgcolor;
  width: 100px;

  .nav-item {
    width: 100%;
    margin-bottom: 5px;

    a {
      .icon-label-group {
        width: 100%;
        padding: 10px 10px;

        .nav-icon {
          svg {
            font-size: 25px;
            color: $light;
          }
        }

        .nav-label {
          font-size: 12px;
          color: $light;
          font-weight: 600;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    .nav-label {
      font-size: 12px;
      color: $light;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 15px;
      margin-top: 3px;
    }

    a.active {
      .icon-label-group {
        border-radius: 5px;
        background-color: $primary-light;

        .nav-icon {
          svg {
            color: $primary;
          }
        }

        .nav-label {
          color: $primary;
        }
      }
    }

    &:hover {
      a {
        .icon-label-group {
          border-radius: 5px;
          background-color: $primary-light;

          .nav-icon {
            svg {
              color: $primary;
            }
          }

          .nav-label {
            color: $primary;
          }
        }
      }
    }
  }
}

//Secondary Navbar Styles
.sidebar--secondary {
  min-height: calc(100vh - 76px);
  padding: 15px 10px;
  background-color: $bg-color;
  width: 260px;
  transition: width 500ms;
  position: relative;

  &.sidebar-collapsed {
    width: 3px;
    transition: width 500ms;
    padding: 0;
    .accordion {
      display: none;
    }
    .btn-icon {
      -webkit-transform: rotate(0deg) !important;
      transform: rotate(0deg) !important;
      display:block !important;
    }
  }
  &.sidebar-hover-collapsed {
    .btn-icon {
      -webkit-transform: rotate(0deg) !important;
      transform: rotate(0deg) !important;
      display:block !important;
    }
  }
  &:hover .sidebar-collapser {
    .btn-icon {
      display:block !important;
    }
  }

  .sidebar-collapser {
    position: absolute;
    width: 24px;
    height: 100%;
    right: -12px;
    top: 24px;
    z-index: 99999;
    .btn-icon {
      display:none;
      height: 24px;
      padding: 0px !important;
      line-height: 1;
      font-size: 10px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      background-color: #fff;
      color: #0081b7;
      text-align: center;
      box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      outline: 0px;
      border: none;
      &:hover {
        background-color: #0081b7;
        color: rgb(255, 255, 255);
      }
    }
    &:hover .btn-icon {
      display:block;
    }
  }

  a {
    text-decoration: none !important;
    .nav-item {
      width: 100%;
      border-radius: 0px;
      padding: 10px;
      color: $dark;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
      border-left: 2px solid #e1e1e1;
      margin-bottom: 0;
      cursor: pointer;

      &:hover {
        border-radius: 0px;
        color: $primary;
        text-decoration: none;
      }
    }
    .pending {
      color: $grey-medium;
    }
  }

  a.active {
    .nav-item {
      border-radius: 0px;
      color: $primary;
      text-decoration: none;
      border-left: 4px solid #0081b7;
    }
  }

  .accordion {
    .card {
      background-color: unset;
      border: 0;

      .card-header {
        border: 0;
        background-color: unset;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        color: #414141;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          border-radius: 5px;
          background-color: $primary-light;
          color: $primary;
          text-decoration: none;
        }
      }

      .sidebar-item {
        &__active {
          border-radius: 5px;
          background-color: $primary-light;
          color: $primary;
          text-decoration: none;
        }
      }

      .collapse,
      .collapsing {
        .card-body {
          padding: 0;
          padding-left: 8px;
        }
      }
    }
  }
}

//Side Modal Styles
.side-modal {
  .modal-dialog {
    height: 100%;
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    .modal-content {
      border: 1px solid #f3f4f5 !important;
      width: fit-content;
      min-width: 25%;
      height: 100vh;
      border-radius: 0;
      background-color: $white;
      border: none;
      overflow: hidden;

      .modal-header {
        border-bottom: none;
      }

      .modal-footer {
        display: none;
      }

      .custom-footer {
        display: block !important;
        text-align: right;
      }

      .modal-body {
        overflow-y: auto;

        .tree-item-label {
          a, span {
            font-size: 16px;
            font-weight: 700;
          }

          .disabled {
            cursor: none;
            opacity: 0.4;
          }
        }

        .tree-children {
          margin-left: 30px !important;

          .tree-item {
            margin-left: 0 !important;
            .tree-item-label {
              padding: 8px 15px !important;

              .tree-item-icon {
                margin-right: 10px !important;

                .minus-icon {
                  color: #89909c;
                  width: 15px;
                  cursor: auto;
                }
              }

              .disabled {
                cursor: none;
                opacity: 0.4;
              }

              a, span {
                font-size: 13px;
              }
              .pointer {
                cursor: pointer;
              }

              &:hover {
                padding: 8px 15px !important;
                border-radius: 4px;
                background-color: #ececec;
              }
            }
          }

          .tree-item-label.active {
            padding: 8px 15px !important;
            border-radius: 4px;
            background-color: #ececec;

            .minus-icon {
              color: $primary !important;
              cursor: auto;
            }
          }
        }
      }
    }
  }
}

.tree-expand-action {
  padding: 5px;
  color: #6d6666;
  font-style: italic;
  span {
    cursor: pointer;
    &:hover {
      color: #0081b7;
    }
  }
  strong {
    padding-right: 10px;
    padding-left: 10px;
  }
}

// Tab Bar
.custom-tab-bar {
  border-bottom: 1px solid $brand-grey-light;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 14px !important;

  .active {
    border: none !important;
    border-bottom: 2px solid $black !important;
    border-color: unset !important;
    color: $dark;
  }

  .nav-link {
    color: $brand-grey-medium;
    font-size: 14px !important;
    border: none;

    &:hover {
      border: none;
      color: $black;
      font-size: 14px !important;
    }
  }
}

// Tab Content
.custom-tab-content {
  padding: 10px;
}

// Date Picker
.react-datepicker-wrapper {
  display: block;
}

// Page Header
.pageheader {
  h4 {
    line-height: 1.5;
  }
}

//Table Styles
.custom-table {
  .search-row {
    background-color: $grey-lighter;
    th {
      padding: 0.5rem;
      border: none;

      .form-group {
        margin-bottom: 0;

        .input-group {
          .input-group-prepend {
            border: 1px solid $grey-light;
            border-right: 0;

            .input-group-text {
              background-color: $white;
              border: 0;
              color: #b8bec3;
            }
          }

          .search-input {
            width: auto;
            border-color: $grey-light;
            border-left: 0;
            padding-left: 0;

            &::placeholder {
              color: $grey-medium;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  tr {
    th {
      color: #616161;
      border-bottom: 2px solid #a6adc6;
      font-weight: 400;
    }

    td {
      vertical-align: middle;
      color: #3a4250;
      border-bottom: 1px solid #e8e8e8;
      font-weight: 600;
      white-space: nowrap;
    }

    .action-buttons {
      display: flex;

      .btn-outline-secondary {
        border-radius: 10px;
        padding: 8px 8px;
        background-color: rgba(255, 255, 255, 0);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: transparent;

        svg {
          font-size: 18px;
          color: #3a4250;
        }
      }
    }
    .table-logo {
      background-size: contain;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      background-color: #f3f4f5;

      .action-buttons {
        .btn-outline-secondary {
          background-color: #f3f4f500;

          svg {
            color: #3a4250;
          }

          &:hover {
            background-color: #d8d8d8;
          }
        }
      }
    }
  }

  td.w-30percent {
    width: 30%;
  }
}

.multi-assignment {
  display: flex;
  justify-content: space-between;
  .search-box {
    .form-control {
      height: calc(1.2em + 0.75rem + 2px) !important;
      font-size: 1rem !important;
    }
  }
  .assignment-container {
    max-height: 500px;
    min-width: 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .assignment-list {
    flex: 1 1 auto;
  }
  .assignment-action {
    flex: 0 0 100px;
    padding-top: 20px;
    text-align: center;
    .btn {
      min-width: 80px !important;
      margin-bottom: 10px;
      padding: 5px !important;
    }
  }
}

.react-datepicker {
  .react-datepicker__day {
    width: 1.5rem;
    line-height: 1.3rem;
    font-weight: 500;
  }
  .react-datepicker__day-name {
    width: 1.5rem;
    line-height: 1.3rem;
    font-weight: 700;
  }
}

.chart-custom-legend {
  padding-top: 20px;
  .legend-item {
    padding-bottom: 10px;
    .legend-btn {
      display: flex;
      cursor: pointer;
      span {
        display: inline-block;
        flex: 1 1 auto;
        word-break: break-word;
        margin-left: 10px;
      }
      .legend-icon {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .legend-disabled {
    .legend-btn {
      .legend-icon {
        background-color: #ccc !important;
      }
      span {
        color: #ccc;
      }
    }
  }
}

// Form Component
.form-group {
  margin-bottom: 25px;

  .form-label {
    color: #1f2539;
    font-size: 13px;
    font-weight: 600;
    font-family: $font-family-sans-serif;
    margin-bottom: 5px;
  }

  .form-control {
    &::placeholder {
      color: #b8bec3;
      font-size: 14px;
    }
  }

  .custom-control-label {
    line-height: 30px;
    padding-left: 10px;

    &:before {
      background-color: #ffff;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #5f5f5f;
    }
  }
  .custom-checkbox {
    z-index: 0;
    .custom-control-input {
      &:checked ~ .custom-control-label {
        &::before {
          background-color: #ffff;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #5f5f5f;
        }
        &::after {
          background-image: url('../assets/images/check.svg');
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 0;
        }
      }
      &:focus ~ .custom-control-label {
        &::before {
          box-shadow: 0;
        }
      }
    }
  }
  .custom-control-input {
    &:active ~ .custom-control-label {
      &::before {
        background-color: #00a751;
      }
    }
  }
}

//Data and Label

.data-view {
  .label {
    margin-bottom: 0;
    font-weight: 700;
    color: #89909c;
  }

  .data {
    font-size: 16px;
    color: #3a4250;
    font-weight: 500;
  }
}

// Table add button
.table-add-button {
  border-radius: 10px;
  padding: 8px 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select {
  width: 185px !important;
}

//Button Styles
.btn-primary {
  border-radius: 4px !important;
  background-color: #00a751 !important;
  font-size: 13px !important;
  padding: 7px !important;
  min-width: 135px !important;
  border: 1px solid #00a751;
  font-weight: bold;
  cursor: pointer;
  @include lg-screen {     
    min-width: auto !important;
  }
  &:hover {
    background-color: darken($color: #00a751, $amount: 10%) !important;
  }

  &:disabled {
    opacity: 0.2;
  }

  &:disabled:hover {
    background-color: #00a751 !important;
  }
}

.btn-danger {
  border-radius: 4px !important;
  background-color: #ff4444 !important;
  font-size: 13px !important;
  padding: 7px !important;
  min-width: 150px !important;
  border: none !important;
  font-weight: bold;

  &:hover {
    background-color: darken($color: #ff4444, $amount: 10%) !important;
  }
}

.btn-secondary {
  border-radius: 4px !important;
  border: 1px solid #3a4250 !important;
  min-width: 135px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  background-color: transparent;
  color: #3a4250;
  padding: 7px;
  @include lg-screen {     
    min-width: auto !important;
  }
  &:hover {
    background-color: #3a4250 !important;
    color: #ffff;
  }
  &.active {
    background-color: #00a751 !important;
  }
}

.btn-reports {
  border-radius: 4px 0 0 4px !important;
}

.btn-parameters {
  border-radius: 0 4px 4px 0 !important;
}

.btn-secondary.btn-lg {
  border-radius: 4px !important;
  border: 1px solid #3a4250 !important;
  min-width: 150px !important;
  font-size: 13px !important;
  padding: 7px !important;
  font-weight: bold !important;
  background-color: transparent;
  color: #3a4250;

  &:hover {
    background-color: #3a4250 !important;
    color: #ffff;
  }
}

.btn-secondary.btn-sm {
  border-radius: 4px !important;
  border: 1px solid #3a4250 !important;
  font-size: 13px !important;
  font-weight: bold !important;
  min-width: auto !important;
}

.back-button {
  color: $primary;
  font-weight: 700;
  font-size: 14px;
  padding-left: 6px;
}

.form-divider {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  span {
    flex-shrink: 0;
    font-size: 18px;
    color: #89909c;
  }

  div {
    background-color: #89909c;
    width: 100%;
    height: 0.8px;
    margin-left: 20px;
  }
}

.required-span {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e66b6b;
}

// Tree View
.tree-view {
  color: $black;
  .form-group {
    margin-bottom: 0 !important;
  }
  .tree-item {
    font-size: 1rem;

    .root-node {
      font-size: 1.25rem;
    }

    .tree-item-label {
      display: flex;
      a {
        color: $black;
        font-weight: bold;

        &:hover {
          text-decoration: none;
        }
      }

      &.active {
        border-radius: 5px;
        background-color: $primary-light;
        color: $primary;
        text-decoration: none;

        a {
          color: $primary;
        }
      }
    }

    .tree-children {
      display: none;
    }

    &.expanded {
      & > .tree-children {
        display: block;
      }
    }

    .tree-item-icon {
      cursor: pointer;
    }
  }

  .search-box {
    .input-group {
      .input-group-prepend {
        border: 1px solid $grey-light;
        border-right: 0;

        .input-group-text {
          background-color: $white;
          border: 0;
          color: #b8bec3;
        }
      }

      .search-input {
        width: auto;
        border-color: $grey-light;
        border-left: 0;
        padding-left: 0;

        &::placeholder {
          color: $grey-medium;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.required-span {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e66b6b;
}

.step-span {
  color: #89909c;
  font-size: 18px;
  padding-left: 0px !important;
}

.tiles-component {
  background: #f3f4f5;
  border-radius: 4px;
  padding: 15px;
}

.tiles-title {
  font-weight: bold;
  color: #1f2539;
  font-size: 13px;
}

.tiles-table-component {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  opacity: 1;

  tr {
    td {
      vertical-align: middle;
      color: #3a4250;
    }

    &:hover {
      background-color: #f3f4f5;
    }
  }
}

.tiles-table-component-active {
  background-color: #0081b71a;
  color: #0081b7;
  text-decoration: none;
}

.tiles-table-component td {
  margin-left: -20px;
  font-size: 0.9em;
  line-height: 1.1em;
  border-top: none !important;
}

.tile-table-wrapper {
  width: 50%;
}

.page-header {
  margin-top: 0 !important;
}
.table-sort-span {
  display: inline-grid;
}
.table-sort-icon {
  margin-bottom: -8px;
}

.table-sort-selected {
  stroke: #616161;
  stroke-width: 2;
}

.pagination-wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-bottom: 15px;

  span {
    text-align: left;
    font: normal normal normal 12px/15px Lato;
    letter-spacing: 0px;
    opacity: 1;
    color: #b8bec3;
    flex: 1;
    margin-left: 20px;
  }

  .pagination-list {
    flex: 1;
    justify-content: flex-end;
    margin-right: 20px;
    margin-bottom: 3px !important;
  }

  ul {
    li {
      a {
        color: #646464 !important;
        font-size: 12px;
        font-weight: 700;
        border-radius: 3px;
        border: 0;
        background-color: transparent;
      }
    }

    li.active {
      a {
        color: #1f2539 !important;
        font-size: 12px;
        font-weight: 700;
        background-color: #f3f4f5 !important;
        border: 0;
      }
    }
  }
}
.pagination-item-active {
  background-color: #f3f4f5;
  border-radius: 3px;
}

.page-header-edit-icon {
  background: #d8d8d8;
  border-radius: 7px;
  width: 28px;
  height: 28px;
  text-align: center;
  cursor: pointer;
}

//Assign Entities Style

.assign-rows {
  .card {
    height: 100%;
  }
}

.available-entity-row {
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
    right: 5px;
    top: 4px;
    font-size: 20px;
    color: #656565;
    display: none;
  }

  &.item-selected{
    background-color: #ececec;
    svg {
      display: block;
    }
  }
}

.assigned-entity-row {
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
    left: 5px;
    top: 4px;
    font-size: 20px;
    color: #656565;
    display: none;
  }

  &:hover {
    background-color: #ececec;

    svg {
      display: block;
    }

    span {
      margin-left: 35px;
    }
  }
}

.pageheader__actions {
  margin-right: 20px;
}

.center-empty-component {
  height: calc(100% - 111px);
}

.header-nav-select {
  width: 150px;
}

.header-select {
  width: 100%;
}

.stop-impersonate {
  &:hover {
    svg, span {
      color: white;
    }
  }
}

.configuration-form-control {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 5px;

  .configuration-row {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    flex-wrap: wrap;
    align-items: center;

    .configuration-col {
      margin-right: 20px;
      padding: 8px 0px;

      .custom-form-control {
        margin-bottom: 0;
        min-width: 170px;
      }

      .btn-secondary {
        padding: 8px 8px;
        min-width: 100px !important;
      }
    }

    .configuration-col.selector {
      .custom-form-control {
        min-width: 100px;
      }
    }

    .submit-button {
      padding: 8px 0px;
      .btn {
        margin-top: 22px;
      }
    }
  }
}

.parameters-section {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
}

.responsive-table {
  display: block;
  height: 500px;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.overFlow {
  overflow: scroll;
}
//dashboard styles
.dashboard {
  .card {
    border: 0;
    box-shadow: 0px 5px 10px #00000014;
    border-radius: 5px;
    padding: 20px;

    .card-title {
      margin-bottom: 25px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .responsive-table {
    display: block;
    height: 352px;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }
}

//bill-integration-emission_profile-dropdown-tree-styles

.dropdown-tree .dropdown-trigger {
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .dropdown-item {
    .dropdown-toggle {
      padding-left: 0 !important;
    }
  }
}

//error feedback style

.invalid-result {
  font-size: x-small;
  color: #e66b6b;
  width: 100%;
  margin-top: 0.25rem;
}
.custom-button {
  border-radius: 4px;
  border: 1px solid #3a4250 !important;
  color: #3a4250;
  padding: 12px;
}
.custom-dashboard-button {
  border-radius: 4px;
  border: 1px solid #3a4250 !important;
  color: #3a4250;
  padding: 12px;
  &:disabled {
    opacity: 0.2;
  }
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.strike {
  text-decoration: line-through !important;
}

.float-left {
  float: left;
}

//report text styles

.report-text {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  fill: #333333;
  text-align: center;
}

//print

.dashboard-download {
  margin-left: 5px;
  padding: 2.3px 12px !important;
  min-width: auto !important;
  &:disabled {
    pointer-events: none;
  }
}

@media print {
  .chartjs-size-monitor {
    display: none !important;
  }
  .print-wrapper {
    margin-top: 50px;
    margin-left: 50px;
  }
  .report-text {
    margin-left: 20px;
  }

  .table td {
    background-color: inherit !important;
    overflow: hidden;
  }

  .chart_print_wrapper {
    margin: 100px 50px;
  }

  .multi_report_wrapper {
    @media print {
      display: 'block';
      page-break-before: 'always';
    }
  }
  .report-header {
    display: block !important;
  }
}

.favourite-report-block {
  text-decoration: none !important;
  .card {
    min-height: 110px;
    width: 100%;
  }
  .add-schedule-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .fav-visibility {
    display: block;
    color: #6c757d !important;
    font-size: 0.9em;
  }
  .date-range-label {
    border-radius: 15px;
    border: 1px solid #18a093;
    display: block;
    padding: 5px 10px;
    color: #18a093;
    font-size: 0.8em;
    margin-top: 5px;
  }
  .card-body {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 0;
      word-break: break-all;
    }
  }
}

.general-report-card {
  min-height: 90px;
  .card-body {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 0;
    }
  }
}

.report-container {
  padding-bottom: 30px;
  .report-main {
    width: calc(100% - 30px);
  }
  .report-sidebar {
    width: 0px;
  }
  &.sidebar-active {
    .report-main {
      width: calc(100% - 330px);
    }
    .report-sidebar {
      width: 330px;
    }
  }
  .configuration-form-control {
    border-radius: 0px;
    .configuration-col {
      width: 100%;
    }
    .submit-button .btn{
      margin-top: 10px;
    }
    .report-param {
      padding: 20px;
      border-bottom: 1px solid #f1f1f1;
    }
  }
}

.chart-container {
  .chart-graph {
    min-height: 70vh;
    position: relative;
    @media print {
      max-height: auto;
      min-height: auto;
    }
  }
}

.manage-report-page {
  .report-year-range {
    .form-control {
      display: inline-block;
      width: auto;
    }
    .form-group {
      margin-bottom: 0;
    }
    .form-label {
      margin-right: 20px;
    }
  }
}

.report-header {
  display: none;
}

.manage-target-emission-table {
  .react-datepicker-wrapper {
    input {
      width: 100px;
    }
  }
  .select {
    width: 120px !important;
  }
  [class$="-control"] {
    min-height: 20px;
  }
  [class$="-indicatorContainer"] {
    padding: 0px;
  }
  [class$="-ValueContainer"] {
    padding: 0px 8px;
  }
}
#dashboard_charts {
  .card {
    padding: 5px !important;
    .card-body {
      padding: 10px;
    }
  }
  .responsive-table {
    height: auto !important;
    display: table !important;
  }
}
.display-columns {
  padding-top: 10px;
  .btn-link {
    padding: 0;
    font-size: 1em;
    svg {
      margin-right: 10px;
    }
  }
  .report-columns {
    padding: 10px 5px 5px;
    margin-left: 10px;
    .custom-control.custom-switch {
      z-index: unset;
    }
  }
}
.dashboard-report-header {
  flex-direction: row;
  display: flex;
  padding-bottom: 15px;
  .report-text {
    text-align: left !important;
    flex: 1;
    display: block;
  }
  .button-wrapper {
    button {
      padding: 5px !important;
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}
.report-units-input {
  .form-label {
    min-width: 180px;
  }
  .form-control {
    min-width: 250px;
  }
}

.input-percent {
  .form-control {
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url('../assets/images/percent.svg');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.horizontal-radio-group {
  .form-label {
    display: block;
  }
  .form-check {
    display: inline-block;
    margin-right: 35px;
  }
}

.display-block {
  display: block;
}

#chartjs2-tooltip {
  width: max-content;
  border: 1px solid lightgray;
  .header-div {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .total-div {
      display: flex;
      margin-left: 15px;
    }
  }
  th, td {
    border: 1px solid lightgray;
    padding: 3px;
  } 
}

ul.forecast-list {
  list-style-type: "> ";
  margin-left: 50px;
  li {
    margin-bottom: 10px;
  }
}

.base-value-input {
  position: relative;
  b {
    position: absolute;
    top: 10px;
    right: 25px;
  }
}

@media (max-width: 1500px) {       
  .table {
    th, td {
      padding: 0.6rem 0.3rem;
    }
  }
}

.manage-coriolis {
  position: relative;
  .coriolis-carousel {
    margin-left: 50px;
    margin-right: 50px;
    .esg-highlight {
      font-size: 1.2em;
      color: #0081b7;
      padding: 0 0.3rem;
    }
    .btn {
      font-weight: 600;
    }
    .coriolis-section {
      min-height: calc(100vh - 76px);
    }
    &>.carousel-control-prev {
      margin-left: -50px;
      width: 50px;
    }
    &>.carousel-control-next {
      margin-right: -50px;
      width: 50px;
    }
    .carousel-control-prev-icon, 
    .carousel-control-next-icon {
      filter: invert(1) grayscale(100);
    }
    .stick-icon {
      margin-right: 5px;
      color: #0081b7;
    }
    .list-group-item {
      border: none !important;
      padding-left: 0;
    }
    .italic-item {
      font-style: italic;
    }
    .carousel-indicators {
      bottom: 0;
      z-index: 9999;
      li {
        background-color: #4b5c6b;
      }
    }
    .image-carousel {
      padding-bottom: 2rem;
      padding-right: 2.5rem;
      .carousel-control-prev {
        display: none;
      }
      margin: 0 auto;
    }
    .methodology {
      .list-group-item {
        padding-bottom: 0.5rem;
      }
      .knowledge-group {
        padding-left: 1.5rem;
        li {
          padding: 0.3rem 0 0.3rem 0.5rem;
        }
      }
      .methodology-line {
        display: inline-block;
        padding: 0.2rem 2.2rem;
        position: relative;
        display: flex;
        align-items: center;
        span {
          left: 0.5rem;
          position: absolute;
          display: inline-block;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          margin-right: 0.6rem;
          top: 50%;
          margin-top: -0.6rem;
        }
      }
    }
  }
  .coriolis-pagination {
    position: absolute;
    bottom: -30px;
    right: 55px;
  }
  h2 {
    color: #0081b7;
    font-weight: 900;
    font-size: 1.8rem;
  }
  h5 {
    font-weight: 700;
    color: #0081b7;
  }
  .key-feature-top {
    border-bottom: 2px solid #e6e9e9;
  }
  .key-feature-bot {
    padding-top: 30px;
  }
  .circle-container {
    .list-group-item {
      padding: 0 !important;
    }
    .circle-number {
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 2rem;
      background-color: #0081b7;
      border-radius: 50%;
      text-align: center;
      line-height: 2rem;
      color: #fff;
      font-weight: 900;
      font-size: 1.3em;
      z-index: 1;
    }
    .circle-body {
      padding-left: 3rem;
    }
    .circle-connector {
      position: absolute;
      height: 100%;
      width: 0.2rem;
      border-left: 0.2rem dashed #c3cfd9;
      top: 1rem;
      left: 1rem;
      z-index: 0;
    }
  }
}

.coriolis-first .coriolis-carousel > .carousel-control-prev,
.coriolis-last .coriolis-carousel > .carousel-control-next {
    display: none;
}
