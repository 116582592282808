//report legend styles
.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.chart-legend {
  height: 100px;
  overflow: auto;
  padding-top: 20px;
}

.chart-legend ul {
  overflow: hidden;
  width: 100%;
}

.chart-legend li {
  cursor: pointer;
  width: 33.33%;
  float: left;
  list-style: none;
}
