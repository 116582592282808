//Extra small devices (phones, 425px and down) */
@mixin extraSmallDevices() {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin primaryRegular() {
  font-family: $font-primary;
  font-weight: 400;
}
@mixin primaryMedium() {
  font-family: $font-primary;
  font-weight: 500;
}
@mixin primarySemiBold() {
  font-family: $font-primary;
  font-weight: 600;
}
@mixin primaryBold() {
  font-family: $font-primary;
  font-weight: 700;
}

// font secondary----
@mixin secondaryLight() {
  font-family: $font-secondary;
  font-weight: 300;
}
@mixin secondaryRegular() {
  font-family: $font-secondary;
  font-weight: 400;
}
@mixin secondaryMedium() {
  font-family: $font-secondary;
  font-weight: 500;
}
@mixin secondarySemiBold() {
  font-family: $font-secondary;
  font-weight: 600;
}
@mixin secondaryBold() {
  font-family: $font-secondary;
  font-weight: 700;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @mixin smallDevices() {
// }

/* Medium devices (landscape tablets, 768px and up) */
// @mixin mediumDevices() {
// }

/* Large devices (laptops/desktops, 992px and up) */
// @mixin largeDevices() {
// }

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @mixin extraLargeDevices() {
// }
