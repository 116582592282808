.disclosure-custom {
    .badge {
        border-radius: 4px;
        padding: 3px 8px;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: $font-secondary;
    }

    // checkbox - radio
    .form-check {
        border: 0.0625rem solid $light;
        border-radius: 0.25rem;
        padding: 0.4375rem 0.875rem 0.4375rem 2.5rem;
        display: inline-block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;

        &-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked~.form-check-label::before {
                border: 0.375rem solid $primary;
                background-color: white;

                &::after {
                    display: block;
                }
            }
        }

        &-input[type='checkbox']~.form-check-label {
            &::before {
                border-radius: 2px;
            }
        }

        &-label {
            cursor: pointer;
            font-size: 16px;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                height: 1rem;
                width: 1rem;
                background-color: #fff;
                border-radius: 50%;
                border: 0.0625rem solid #d9e2ec;
                content: '';
                top: 0.6875rem;
                left: 0.875rem;
            }

            &::after {
                content: '';
                position: absolute;
                display: none;
                top: 0.5625rem;
                left: 0.5625rem;
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                background: white;
            }
        }

        &:hover {
            input~.form-check-label::before {
                background-color: $primary;
                opacity: 0.6;
            }

            input[type='checkbox']~.form-check-label::before {
                background-color: $primary;
                opacity: 0.6;
            }
        }
    }
}

.disclosure-page {
    .sgd-summary-header {
        flex-direction: row;
        align-items: flex-end;
        display: flex;
        span {
            flex: 1
        }
        button {
            max-width: 250px;
        }
        .spinner-border {
            width: 1em;
            height: 1em;
        }
    }
    #goal-summary-chart {
        height: 70vh;
    }

    .inner-content {
        padding: 20px;
    }

    .placeholder {
        margin: auto;
        text-align: center;

        img {
            max-width: 500px;
            margin-bottom: 2rem;
        }

        h5 {
            font-family: $font-secondary;
            font-weight: 300;
            color: $dark;
            margin-bottom: 2rem;
        }
    }

    .disclosure-card {
        display: flex;
        flex-direction: row;
        width: 100%;

        .image-section {
            width: 40%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        .text-section {
            display: flex;
            align-items: center;
            padding: 15px;

            .text-section-wrapper {
                h5 {
                    font-size: 18px;
                    font-family: $font-secondary;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    color: #627D98;
                }
            }
        }
    }
}

.sdg-promt-modal {
    .modal-body {
        padding-top: 0 !important;

        img {
            width: 130px;
            height: auto;
            margin-bottom: 10px;
        }

        h4 {
            font-family: $font-secondary;
            font-size: 24px;
        }

        p {
            font-size: 16px;
            color: #102A43 !important;
        }

        button {
            display: block;
            margin: auto;
        }
    }
}

.disclosure-modal {

    .modal-90w {
        min-width: 90%;
    }

    .modal-header {
        border-bottom: 2px solid #D9E2EC !important;
        padding-bottom: 15px !important;
        padding-top: 20px !important;
        padding-left: 20px !important;
    }

    .modal-body {
        padding: 0 !important;

        .container-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;

            .navigation-menu {
                width: 30%;
                background-color: #F0F4F8;
                max-height: 670px;
                overflow-y: scroll;

                .flex-column {
                    margin-left: 15px;
                    margin-top: 15px;

                    .nav-item {
                        a {
                            font-family: $font-secondary;
                            font-size: 14px;
                            font-weight: 600;
                            color: #102A43;
                            background-color: transparent;
                            border-radius: 8px 0px 0px 8px;

                            img {
                                float: right;
                            }

                        }

                        a.active {
                            background-color: #627D98;
                            color: #fff !important;
                        }
                    }
                }
            }

            .tab-content-side {
                width: 70%;
                padding: 15px;

                .goals-card-header {
                    .goals-card-header-wrapper {
                        display: flex;

                        .text-side {
                            padding-left: 15px;
                            width: 100%;

                            .text-side-header {
                                display: flex;
                                width: 100%;
                                justify-content: space-between;

                                h5 {
                                    font-family: $font-secondary;
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .kpi-card-wrapper {
                    overflow-y: scroll;
                    max-height: 500px;
                }

                .kpi-card {
                    padding: 0;
                    overflow: hidden;

                    .card-header {
                        background-color: #fff;
                        padding: 10px;
                        border-bottom: 0;
                        padding-bottom: 0;

                        .kpi-version-label {
                            background-color: #F0F4F8;
                            color: #102A43;
                            font-size: 12px;
                            border-radius: 4px;
                            padding: 5px 10px;
                            width: fit-content;
                            font-weight: 500;
                        }
                    }

                    .card-body {
                        padding: 10px;

                        .description {
                            font-size: 14px;
                        }

                        label {
                            font-size: 16px;
                            font-weight: 500;
                        }

                        .form-group {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

}

.disclosure-page-card {
    padding: 0 !important;
    overflow: hidden;

    .container-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;

        .navigation-menu {
            width: 30%;
            background-color: #F0F4F8;

            .card-header-pills {
                margin-right: 0 !important;
            }

            .flex-column {
                margin-left: 15px;
                margin-top: 15px;

                .nav-item {
                    a {
                        font-family: $font-secondary;
                        font-size: 14px;
                        font-weight: 600;
                        color: #102A43;
                        background-color: transparent;
                        border-radius: 8px 0px 0px 8px;

                        img {
                            float: right;
                        }

                    }

                    a.active {
                        background-color: #627D98;
                        color: #fff !important;
                    }
                }
            }
        }

        .tab-content-side {
            width: 70%;
            padding: 15px;

            .goals-card-header {
                .goals-card-header-wrapper {
                    display: flex;

                    .text-side {
                        padding-left: 15px;
                        width: 100%;

                        .text-side-header {
                            display: flex;
                            width: 100%;
                            justify-content: space-between;

                            h5 {
                                font-family: $font-secondary;
                                font-size: 24px;
                                font-weight: 600;
                            }
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }

            .kpi-card {
                padding: 0;
                overflow: hidden;

                .card-header {
                    background-color: #fff;
                    border-bottom: 0;
                    padding: 18px 20px;
                    padding-bottom: 0;

                    .kpi-version-label {
                        background-color: #F0F4F8;
                        color: #102A43;
                        font-size: 12px;
                        border-radius: 4px;
                        padding: 5px 10px;
                        width: fit-content;
                        font-weight: 500;
                    }
                }

                .card-body {
                    padding: 18px 20px;
                    padding-top: 8px;

                    .description {
                        font-size: 14px;
                    }

                    .kpi-card-slider {
                        display: flex;
                        justify-content: center;
                        flex-wrap: flex;
                        margin: 20px 0px;
                        z-index: 1000;
                        cursor: pointer;
                    }
                }
            }

            .kpi-card.bg-light {
                background-color: #fff !important;

                .not-applicable {
                    color: #e12222;
                    background-color: #f7dada;
                    font-size: 12px;
                    border-radius: 4px;
                    padding: 5px 10px;
                    width: fit-content;
                    font-weight: 500;
                    margin-left: 10px;
                }

                .card-body {
                    .kpi-card-slider {
                        opacity: 0.4;
                    }

                    .description {
                        opacity: 0.4;
                    }
                }
            }

            .success-share {
                display: flex;

                .goal-achieved {
                    background-color: #C0E8D4;
                    border-radius: 4px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    color: #2DCC7A;
                    font-family: $font-secondary;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 8px 5px !important;
                }

                .share-btn {
                    border-radius: 4px !important;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    padding: 8px 5px !important;
                    min-width: 115px !important;
                }
            }
        }
    }
}

.kpi-compact-detail {

    .goals-card-header-wrapper {
        display: flex;

        .text-side {
            padding-left: 15px;
            width: 100%;

            .text-side-header {
                display: flex;
                width: 100%;
                justify-content: space-between;

                h5 {
                    font-family: $font-secondary;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            p {
                font-size: 14px;
            }
        }
    }

    .kpi-version-label {
        background-color: #F0F4F8;
        color: #102A43;
        font-size: 12px;
        border-radius: 4px;
        padding: 5px 10px;
        width: fit-content;
        font-weight: 500;
        height: fit-content;
    }

}